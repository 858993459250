.section {
    &.slider_v1 {
        padding-top: 45px;

        &.slider-border {

            border: 1px solid #d4d5d6;
        }

        .slider_wrapper {
            .slider_item {
                &.slider-item-left {
                    .slider-img {
                        float: left !important;
                        margin-right: 40px;
                        width: 50%;
                        max-width: 545px;
                    }

                    .item-content {
                        text-align: left;
                    }

                    &.vertical-center {
                        .slider-img {
                            float: none !important;
                            display: inline-block !important;
                            vertical-align: middle;
                        }

                        .item-content {
                            display: inline-block;
                            max-width: calc(50% - 50px);
                            vertical-align: top; // middle

                            @media(max-width:768px) {
                                vertical-align: unset;
                                max-width: 100%;
                                display: block;
                            }
                        }

                        @media(max-width: 768px) {
                            .slider-img {
                                display: block !important;
                                vertical-align: unset;
                            }

                            .item-content {
                                display: block;
                            }
                        }
                    }

                    @media(max-width: 768px) {
                        .slider-img {
                            float: none !important;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }
                }

                &.slider-item-right {

                    .slider-img {
                        float: right !important;
                        margin-left: 40px;
                        width: 50%;
                        max-width: 545px;
                    }

                    .item-content {
                        text-align: right;
                    }

                    &.vertical-center {
                        .slider-img {
                            float: none !important;
                            display: inline-block !important;
                            vertical-align: middle;
                        }

                        .item-content {
                            display: inline-block;
                            max-width: calc(50% - 50px);
                            vertical-align: top; // middle

                            @media(max-width:768px) {
                                vertical-align: unset;
                                max-width: 100%;
                                display: block;
                            }
                        }

                        @media(max-width: 768px) {
                            .slider-img {
                                display: table-header-group;
                                vertical-align: unset;
                            }

                            .item-content {

                                display: table-footer-group !important;
                            }
                        }
                    }

                    @media(max-width: 768px) {
                        .slider-img {
                            float: none !important;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                        }

                        .item-content {
                            text-align: left;
                        }
                    }
                }

                .slider-link {

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* New syntax */
                .slider-img {
                    width: 100%;
                    height: auto;
                    float: none;
                    margin-bottom: 40px;

                }

                .item-content {
                    display: block;
                }
            }
        }

        .container-fluid {
            .slider_wrapper {
                .slider_item {
                    &.slider-item-left {

                        &.vertical-center {
                            .item-content {
                                max-width: calc(70% - 50px);

                                @media(max-width:768px) {
                                    vertical-align: unset;
                                    max-width: 100%;
                                    display: block;
                                }
                            }

                        }
                    }

                    &.slider-item-right {

                        &.vertical-center {
                            .item-content {
                                max-width: calc(70% - 50px);

                                @media(max-width:768px) {
                                    vertical-align: unset;
                                    max-width: 100%;
                                    display: block;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}