.banners_wrapper {
    .banner_item {
        position: relative;

        @media(max-width: $media_md) {
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin-bottom: 15px;
            }


        }

        .banner-link {
            &:hover {
                text-decoration: none;
            }
        }

        .banner-content {
            position: absolute;
            top: 40%;
            width: calc(100% - 30px);
            text-align: center;
            padding: 10px 0;
            background: rgba(color(dark_bg), 0.3);
            pointer-events: none;

            .banner-title {
                font-weight: 300;
                font-family: font(main);
                font-weight: 600;
                font-size: 16px;
                color: color(white-clr);
                text-transform: uppercase;
            }
        }
    }

}

.group-view-section {
    .container-fluid {
        .banners_wrapper {
            img {
                width: 100%;
            }

            .banner-content {


                .banner-title {
                    font-size: 22px;
                }
            }
        }
    }
}