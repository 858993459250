.section {
    &.bullet-section {
        .container-fluid {
            .row {
                @media(min-width: 1200px) {


                    &:not(:first-of-type) {
                        justify-content: center;

                        .col-12 {
                            flex: 0 0 66.66667%;
                            max-width: 66.66667%;
                        }
                    }
                }
            }

        }

        color: color(white-clr);

        .bullet-title {
            color: color(white-clr);
        }

        hr.bullet-separator {

            background: color(white-clr);
            color: color(white-clr);

            &:before {
                color: color(white-clr);
                background: color(white-clr);
            }

            &:after {
                color: color(white-clr);
                background: color(white-clr);
            }

        }

        .column-2 {
            -webkit-columns: 2;
            -moz-columns: 2;
            columns: 2;

            @media (max-width: $media_md) {
                -webkit-columns: 1;
                -moz-columns: 1;
                columns: 1;
                margin: 0 auto;
            }
        }

        .bullet-list {
            margin-top: 20px;

            ol {
                column-fill: auto;
                font-weight: 300;
                list-style-type: disc;

                li {
                    font-size: 15px;
                    font-weight: 300;
                    font-family: font(sub);
                    margin-bottom: 10px;

                    strong {
                        font-weight: 700;
                    }
                }

            }

        }
    }
}