.section {
    &.detail-section {


        .detail-text {
            &.column-2 {
                -webkit-columns: 2;
                -moz-columns: 2;
                columns: 2;

                @media (max-width: $media_md) {
                    -webkit-columns: 1;
                    -moz-columns: 1;
                    columns: 1;
                    margin: 0 auto;
                }
            }
        }
    }
}