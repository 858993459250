/* SLIDER VARS */
$slider_height_sm: 100vh;
$slider_height_md: 100vh;
$slider_height_lg: 100vh;
$slider_height_xl: 100vh;
$slider_height: 100vh;

/* SLIDER STYLES */
.header-slider-wrapper {
    overflow: visible; // hidden for for arrows to be inside

    .header-slider-item {
        // background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // height: 720px;
        height: 100vh;

        .container {
            height: 100vh;
        }

        img {
            width: 100%;
        }
    }

    /* SLICK NAVIGATION ARROWS */
    .navi {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        z-index: 1;
        cursor: pointer;
        font-size: 30px;
        background: transparent;
        border: none;
        line-height: 30px;
        width: 30px;
        height: 30px;


        &.navi-prev {
            left: 1px;
            color: color(white-clr);
        }

        &.navi-next {
            right: 1px;
            color: color(white-clr);
        }

        &.slick-disabled {
            @include opacity(0.2);
            pointer-events: none;
            cursor: default;
        }

        i {
            font-size: 2.142857142857143rem;
            font-weight: 600;
        }
    }

    .navi {
        color: #ffffff;
    }

    .slick-dots {
        position: absolute;
        bottom: -70px;
    }

    #video-holder {
        .play-btn-bg {
            left: 47%;
        }
    }
}

.header-slider-content {
    font-size: 1.8rem;
    font-weight: 400;
    width: 100%;
    color: color(white-clr);
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 200px;
    @media(max-width: 740px) and (orientation: landscape){
        bottom: 0px;
    }
    @media(max-width: 480px) and (orientation: portrait){
        bottom: 85px;
    }
    @media(max-width: 359px) and (orientation: portrait){
        bottom: 25px;
    }
    @media(max-height: 900px){
        bottom: 160px;
    }
    @media(max-height: 800px){
        bottom: 100px;
    }
    @media(max-height: 670px){
        bottom: 50px;
    }

    .header-slider-title {
        font-family: font(main);
        font-weight: 600;
        font-weight: 600;
        font-size: 50px;
        line-height: 1.1;
        margin-bottom: 20px;
        max-width: 100%;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

        strong,
        b,
        em,
        i {
            display: block;
            font-weight: 700;
        }
    }

    .header-slider-separator {
        border: none;
        height: 3px;
        background: color(white-clr);
        color: color(white-clr);
        width: 100px;
        position: relative;

        &:before {
            content: '';
            height: 1px;
            color: color(white-clr);
            background: color(white-clr);
            width: 50px;
            display: block;
            position: absolute;
            left: -50px;
            top: 1px;
        }

        &:after {
            content: '';
            height: 1px;
            color: color(white-clr);
            background: color(white-clr);
            width: 50px;
            display: block;
            position: absolute;
            right: -50px;
            top: 1px;
        }

    }

    p {
        margin-bottom: 30px;
        line-height: 26px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
        font-family: font(sub);
        font-size: 16px;
    }

    .header-slider-text {
        margin: 0 auto 30px auto;
        max-width: 35%;
        text-align: center;
        
        p {
            margin: 0;
            line-height: 26px;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
            font-family: font(sub);
            font-size: 16px;
            @media(max-width: 700px) {
                font-size: 14px;
            }
        }
        @media(max-width: 1400px){
            max-width: 45%;
        }
        @media(max-width: 1100px){
            max-width: 55%;
        }
        @media(max-width: $media_lg) {
            max-width: 65%;
        }
        @media(max-width: $media_md){
            max-width: 85%;
        }
        @media(max-width: $media_sm){
            max-width: unset;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &.text-right {
        p {
            margin-left: auto;
        }
    }
}

/* YouTube and Image Overlay */
#video-holder {
    position: relative;
    width: 100%;
    height: 100%;

    .img-overlay {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;

    }

    .play-btn-bg {
        position: absolute;
        top: 50%;
        margin: -100px 0 0 -26px;
        left: 45%;
        padding: 1%;
        border-radius: 50%;
        background: color(primary);

        .video-play-btn {
            width: 150px;
            height: 150px;
            background: transparent;
            border-radius: 50%;

            i {
                font-size: 4rem;
                margin: 0 auto;
                color: color(white-clr);
            }
        }
    }
}





/* SLICK NAVIGATION DOTS */
.slick-dots {
    position: absolute;
    bottom: -70px;
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0;

    li {
        display: inline-block;
        margin: 0 4px;

        button {
            border: 2px solid color(primary);
            background-color: color(white-clr);
            border-radius: 100%;
            width: 12px;
            height: 12px;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            outline: 0;
        }

        &.slick-active {
            button {
                background: color(primary);
            }
        }
    }
}

.slider-buttons {
    a {
        img {
            width: auto !important;
            display: inline-block;
        }

        &:last-child {
            margin-left: 10px;

            @media (max-width: 340px) {
                margin-left: 0;

                img {
                    margin-top: 10px;
                }
            }
        }
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_xxl) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }


    }
}

@media (max-width: $media_xl) {
    .header-slider-wrapper {

        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }
    }



}

@media (max-width: $media_lg) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }
    }
}

@media (max-width: $media_md) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_md;

            .container {
                height: $slider_height_md;
            }
        }
    }

    .navi {
        display: none !important;
    }

    #video-holder {
        .play-btn-bg {
            left: 47%;

            .video-play-btn {
                width: 75px;
                height: 75px;

                i {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media (max-width: $media_sm) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_sm;

            .container {
                height: $slider_height_sm;
            }
        }
    }

    .header-slider-content {


        .header-slider-title {
            font-size: 38px;
        }
    }
}

@media (max-width: $media_xs) {
    .header-slider-content {


        .header-slider-title {
            font-size: 32px;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
        }

        .header-slider-text {
            p {
                text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
            }
        }
    }
}

body#mobileapp-page {
    .header-slider-content {
        @media (max-width: $media_sm) {
            position: relative;
            top: 50px;
        }
    }
}