.contact-block-section {


    .btn-wrapper {
        text-align: center;
    }

    .contact-btn {

        background: color(primary);
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(sub);
        font-size: 16px;
        font-weight: 700;
        color: color(white-clr);
        padding: 7px 15px;
        @include transition(all .3s linear);

        &:hover {
            color: color(text-darker);
            background: transparent;
            border: 2px solid color(primary);
        }
    }

}

.home-contact-section {
    padding-top: 20px;
    padding-bottom: 20px;

    ul {
        li {
            font-size: 14px;
            font-family: font(sub);

            &:not(:last-of-type) {
                border-right: 1px solid #cccccc;
                padding-right: 0.5rem;
            }

            .label {
                font-weight: 500;
            }
        }
    }
}