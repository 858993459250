.section {
    &.newsletter-section {
        .newsletter-title {
            color: color(white-clr);
        }

        hr.newsletter-separator {

            background: color(white-clr);
            color: color(white-clr);

            &:before {
                color: color(white-clr);
                background: color(white-clr);
            }

            &:after {
                color: color(white-clr);
                background: color(white-clr);
            }

        }

        .newsletter-text {
            color: color(white-clr);
        }

        label {
            color: color(white-clr);
            font-family: font(sub);
            font-size: 14px;
            text-align: center;
        }

        input {
            background: transparent;
            @include border-radius(0px);
            border: 2px solid color(white-clr);
            color: color(white-clr);
            text-align: center;
            @include placeholder(14px, 500, italic, color(placeholder_text));

            &:focus {
                box-shadow: unset;
                border: 2px solid color(border_light);
            }
        }

        .cb {
            i {


                &.fas {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    display: none;
                }
            }
        }

        .newsletter-btn {
            background: color(white-clr);
            color: color(primary);
            border: 1px solid color(white-clr);
            text-transform: uppercase;
            margin-left: calc(50% - 89px);
            @include border-radius(2px);

            &:hover {
                color: color(white-clr);
                background: transparent;
            }
        }
    }
}

// popup

.newsletter-confirm0 {
    padding: 40px 20px !important;

    .btn-wrapper {
        margin-top: 10px;
    }

    p {
        font-family: font(sub);
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        padding-bottom: 1rem;
        display: inline-block;
        margin-left: 10px;
    }
}