.section {
    &.slider_v2 {
        &.dm_counter_slider {
            .container-fluid {
                .slider_item {
                    text-align: center;
                }
            }

            .slider-title {
                color: color(white-clr);
            }

            hr.slider-separator {

                background: color(white-clr);
                color: color(white-clr);

                &:before {
                    color: color(white-clr);
                    background: color(white-clr);
                }

                &:after {
                    color: color(white-clr);
                    background: color(white-clr);
                }

            }

            .slider-subtitle {
                color: color(white-clr);
            }

            .slider-text {
                color: color(white-clr);
            }

            .slider_wrapper {
                margin-top: 25px;
            }

            .slider_item {
                @media(max-width: $media_lg){
                    
                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }
                @media(max-width: $media_md) {
                    text-align: center;

                }

                .counter-wrapper {
                    color: color(white-clr);
                    font-size: 27px;
                    font-family: font(main);
                    font-weight: 300;

                    .counter-value {
                        font-weight: 600;
                    }
                }

                .counter-desc {
                    color: color(white-clr);
                    font-family: font(sub);
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        &.dm_logo_slider {
            .slider_wrapper {
                .slick-track {
                    margin: 0 auto;
                }

                .slider_item {
                    text-align: center;
                    @include opacity(0.6);
                    @include transition(opacity .3s linear);

                    &:hover {
                        @include opacity(1);
                    }

                    .slider-img {
                        margin: 0 auto;
                    }
                }

            }
        }
    }
}