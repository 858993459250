.section {
    &.slider_v3 {
        padding-bottom: 0;
        .grid_slider_init{
            @media(max-width: $media_sm){
                margin-left: 0;
                margin-right: 0;
            }
        }
        .slider-title{
            margin-bottom: 80px;
        }

        .slider_item {
            margin-bottom: 60px;
            padding-left: 0;
            padding-right: 0;
            &.slider-item-left {
                .slider-img {
                    float: left;
                    margin-right: 20px;
                }
            }

            &.slider-item-right {
                .slider-img {
                    float: right;
                    margin-left: 20px;
                }
            }

            .slider-img {
                margin: 0 auto;
                margin-bottom: 10px;
                display: block;
            }

            .item-content {
                &:before {
                    content: '';
                    height: 100%;
                    color: color(border_light);
                    background: color(border_light);
                    width: 1px;                    
                    position: absolute;
                    left: 2px;
                    z-index: -1;
                }
                
                .item-title {
                    border-left: 5px solid color(primary);
                    padding-left: 20px;
                    margin-bottom: 20px !important;
                    text-transform: none !important;
                    font-weight: 500 !important;
                    @media(min-width: $media_md){
                        padding-right: 20px;
                    }
                }

                .item-desc {
                    color: color(text-dark);
                    @include page-text();
                    padding-left: 20px;
                    padding-right: 20px;
                    p {
                        color: color(text-dark);
                    }
                }

                .slider-item-link {
                    color: color(primary);

                    &:hover {
                        color: color(text-dark);
                    }
                }
            }
        }
    }
}