/* COOKIE COMPONENT STYLES */
.cookie-component {
    position: fixed;
    right: 20px;
    bottom: 20px;
    max-width: 230px;
    background-color: $footer_bg;
    padding: 20px;
    color: #ffffff;
    border-radius: $border_radius;
    box-shadow: 0 0 18px rgba(0,0,0,0.1);
    display: none;
    p {
        font-size: 1.6rem;
        margin-bottom: 15px;
        a {
            font-weight: 600;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &.close-cookie {
        -webkit-animation: cookieCloseAnim 0.4s ease-in forwards;
        animation: cookieCloseAnim 0.4s ease-in forwards;
    }
}

@-webkit-keyframes cookieCloseAnim {
    0% {
        right: 20px;
        opacity: 1;
    }
    40% {
        right: 40px;
    }
    100% {
        right: -100%;
        opacity: 0;
    }
}

@keyframes cookieCloseAnim {
    0% {
        right: 20px;
        opacity: 1;
    }
    40% {
        right: 40px;
    }
    100% {
        right: -100%;
        opacity: 0;
    }
}