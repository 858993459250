.section {

    &.terms-and-conditions-section {
        .terms-and-conditions-text {
            margin-top: 30px;
        }
    }
}

.terms-and-conditions-popup0 {
    position: fixed !important;
    top: 10% !important;
    visibility: hidden;
    width: 100% !important;
    
    max-width: 1140px;
    padding-bottom: 20px !important;
    .terms-close{
        position: absolute;
        top: 25px;
        right: 35px;
        i{
            color: #000;
            font-size: 22px;
        }
    }

    #content {
        max-height: 800px;
        overflow-y: scroll;
        padding-left: 30px;
        padding-right: 30px;
        // firefox scrollbar
        overflow-y: scroll;
        scrollbar-color: rgba(color(primary), 0.7) transparent;

        //internet explorer scrollbar
        scrollbar-face-color: color(primary);
        scrollbar-arrow-color: color(primary);
        scrollbar-track-color: transparent;
        scrollbar-shadow-color: rgb(0, 0, 0);
        scrollbar-highlight-color: rgb(0, 0, 0);
        scrollbar-3dlight-color: color(primary);
        scrollbar-darkshadow-Color: transparent;

        // webkit browsers (chrome...) scrollbar
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(color(primary), 0.7);
        }
    }
}