//@import "~bootstrap/scss/bootstrap";
// @import "../../node_modules/aos/dist/aos.css";
@import "elements/mixins";
@import "elements/global";

@import "elements/buttons";
@import "elements/bg_img";

@import "elements/header";
@import "elements/footer";
@import "elements/cookie";

@import "elements/main_navigation";
@import "elements/header_slider";

@import "elements/Slider/index";
@import "elements/Page/index";
@import "elements/Group/index";
@import "elements/Detail/index";

@import "elements/blog_detail_component";
@import "elements/blogs_component";
@import "elements/contact_block_component";
@import "elements/about_us_component";
@import "elements/terms_and_conditions_component";
@import "elements/not_found";


@import "elements/newsletter_component";
@import "elements/text_page_component";
@import "elements/sitemap_component";

@import "elements/gmap_component";

@import "elements/slider_component";

@import "elements/form";
@import "elements/scrollbar";

@import "elements/flash_messages";