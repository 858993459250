.section {
    &.blog-section {

        .blog-date {
            font-family: font(sub);
            font-weight: 600;
            font-size: 0.9rem;
            text-align: left;
            display: block;
            margin-top: 1rem;
        }

        .blog-img-container {
            text-align: center;
            margin-top: 3rem;
        }
    }
}