.group-view-section {
    .grid_component_wrapper {
        padding: 40px 0;

        .grid-item {
            margin-bottom: 25px;

            .grid-link {
                &:hover {
                    text-decoration: none;
                }
            }

            .grid-img {
                display: block;

                @media (max-width: 576px) {
                    margin: 0 auto;
                }
            }

            .item-content {
                padding: 10px 15px;
                margin-top: 10px;
            }

            .grid-item-title {
                font-weight: 300;
                font-family: font(main);
                font-weight: 600;
                font-size: 16px;
                color: color(text-dark);
                text-transform: uppercase;
            }

            .grid-link {
                &:hover {
                    text-decoration: none;
                }
            }

            .grid-item-text {
                @include page-text();

                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 14px;
                font-family: font(sub);
                color: color(text-light);
                font-weight: 400;

            }

            .grid-item-link {
                color: color(primary);
                font-size: 14px;
                font-weight: 700;
                font-family: font(sub);

                i {
                    font-size: 16px;
                    color: color(primary);
                    margin-right: 5px;
                }

                &:hover {
                    text-decoration: none;

                    i {
                        -webkit-animation: leftToRight 1s ease-out infinite;
                        animation: leftToRight 1s ease-out infinite;
                    }
                }
            }

        }

    }
}